import React from "react";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";

const NotFoundPage = () => (
  <React.Fragment>
    <ThemeContext.Consumer>
      {theme => (
        <Article theme={theme}>
          <header>
            <Headline title="404 Not Found" theme={theme} />
          </header>
          <p>This page doesn't exist.</p>
        </Article>
      )}
    </ThemeContext.Consumer>
  </React.Fragment>
);

export default NotFoundPage;
